import React, { Component, PureComponent } from 'react';
import './styles/App.css';
import axios from 'axios';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import ApiConfig from './config'

import Welcome from './Welcome/Welcome';
import AdminPanel from './Admin/AdminPanel';
import ClientView from './Client/ClientView';


function bindRouteParamsToProps() {
  return function(EmbedClass) {
    const klass = class extends PureComponent {
      render() {
        const routeParams = this.props.match.params || {};
        return <EmbedClass {...routeParams} {...this.props} />
      }
    };

    return klass;
  }
}
axios.defaults.headers.common['Authorization'] = 'Bearer ' + ApiConfig();


class App extends Component {
  // Render
  // ----------------------------------------------------
  render() {

    return (

      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={bindRouteParamsToProps()(Welcome)} />
          <Route exact path='/admin/:recordId/view' component={bindRouteParamsToProps()(AdminPanel)} />
          <Route exact path='/admin/:recordId' component={bindRouteParamsToProps()(AdminPanel)} />
          <Route exact path='/admin/' component={bindRouteParamsToProps()(AdminPanel)} />

          <Route exact path='/cliente/:recordId' component={bindRouteParamsToProps()(ClientView)} />
        </Switch>
      </BrowserRouter>

    );
  }
}

export default App;
