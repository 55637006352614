import React, { Component } from 'react';
import exit from '../assets/exit.png';
import { Link } from 'react-router-dom';

import ch1 from '../assets/ch/1.png';
import ch2 from '../assets/ch/2.png';
import ch3 from '../assets/ch/3.png';
import ch4 from '../assets/ch/4.png';
import ch5 from '../assets/ch/5.png';
import ch6 from '../assets/ch/6.png';
import ch7 from '../assets/ch/7.png';
import ch8 from '../assets/ch/8.png';

export default class EditView extends Component {
  constructor(props) {
    super();
    this.state = {
      project: true,
      client: true,
      chapters: true,
      images: true,
      documents: true,
      oldChapters: {},
      sendingUpdate: false,
    }
  }

  h2Toggle = e => {
    if (e === 'client') {
      if (this.state.client) {
        this.setState({
          client: false,
        })
      } else {
        this.setState({
          client: true,
        })
      }
    } else if (e === 'project') {
      if (this.state.project) {
        this.setState({
          project: false,
        })
      } else {
        this.setState({
          project: true,
        })
      }
    } else if (e === 'chapters') {
      if (this.state.chapters) {
        this.setState({
          chapters: false,
        })
      } else {
        this.setState({
          chapters: true,
        })
      }
    } else if (e === 'documents') {
      if (this.state.documents) {
        this.setState({
          documents: false,
        })
      } else {
        this.setState({
          documents: true,
        })
      }
    } else if (e === 'images') {
      if (this.state.images) {
        this.setState({
          images: false,
        })
      } else {
        this.setState({
          images: true,
        })
      }
    }
  }
  uploadDoc = (e, i, type, outerI) => {
    this.setState({
      uploadI: i,
      uploadType: type,
      uploadOuterI: outerI,
    })
    const uploadDoc = async e => {
      const files = e.target.files;
      const data = new FormData();
      data.append('file', files[0]);
      data.append('upload_preset', 'crodadmin');
      const res = await fetch(
        'https://api.cloudinary.com/v1_1/radcollab/image/upload',
        {
          method: 'POST',
          body: data,
        }
      );
      const file = await res.json();

      this.setState({
        uploadedImg: file.secure_url,
      })
      setTimeout((function() {
        moveToRec();
      }), 20);
    }
    uploadDoc(e);

    let moveToRec = function(i) {
      this.props.addingDoc(this.state.uploadedImg, this.state.uploadI, this.state.uploadType, this.state.uploadOuterI);
    }.bind(this);
  }

  removeItem = (e, i, property, outerI) => {
    let currentRec = this.props.currentRecord;



    if (outerI !== undefined) {
      currentRec[e][outerI][property].splice(i, 1);
    } else {
      currentRec[e].splice(i, 1);
    }
    this.props.updateItem(currentRec);
  }

  checkEmail = e => {
    e.preventDefault()

    let theChecks = e.target.querySelectorAll('input[type="checkbox"]:checked');



    let checked = [];
    for (var i in theChecks) {
      if (theChecks[i].value) {
        checked.push(theChecks[i].value);
      }
    }

    let finalChanged = [];

    let finalChange = function(checked, i) {
      let newObj = {}
      let thisCh = this.props.currentRecord.Chapters.filter(e => e.slug === checked[i])[0];
      newObj.name = thisCh.name;
      newObj.status = thisCh.status;

      finalChanged.push(newObj);

      if (i !== checked.length - 1) {
        finalChange(checked, i + 1);
      }
    }.bind(this);

    if (checked.length > 0) {
      finalChange(checked, 0);
    }



    this.props.callAPI(finalChanged);

  }

  addItem = (e, i) => {

    let currentRec = this.props.currentRecord;

    if (e === 'client') {
      if (!currentRec['Client Information']) {
        currentRec['Client Information'] = [];
      }
      let theObj = {
        email: '',
        name: '',
        password: ''
      }
      currentRec['Client Information'].push(theObj)

    } else if (e === 'chapter') {
      if (!currentRec['Chapters']) {
        currentRec['Chapters'] = [];
      }
      let theObj = {
        icon: 0,
        name: '',
        slug: '',
        status: '0',
        updated: new Date(),
      }
      currentRec['Chapters'].push(theObj)

    } else if (e === 'task') { //needs i
      let theObj = {
        completed: 'false',
        name: '',
      }
      if (!currentRec['Chapters'][i].tasks) {
        currentRec['Chapters'][i].tasks = [theObj];
      } else {
        currentRec['Chapters'][i].tasks.push(theObj)
      }
      setTimeout((function() {
        let objDiv = document.getElementById(i + '-tasks');
        objDiv.scrollTop = objDiv.scrollHeight;
      }), 50);

    } else if (e === 'image') {
      if (!currentRec['Images']) {
        currentRec['Images'] = [];
      }
      let today = new Date()
      today = (today.getMonth()+1) + '/' + today.getDate() + '/' + today.getFullYear();
      let theObj = {
        caption: '',
        chapters: [],
        date: today,
        url: '',
      }
      currentRec['Images'].push(theObj)

    } else if (e === 'document-category') {
      if (!currentRec['Documents']) {
        currentRec['Documents'] = [];
      }
      let theObj = {
        category: '',
      }
      currentRec['Documents'].push(theObj)

    } else if (e === 'document') { //needs i
      let theObj = {
        date: new Date(),
        name: '',
        url: '',
      }
      if (!currentRec['Documents'][i].documents) {
        currentRec['Documents'][i].documents = [theObj];
      } else {
        currentRec['Documents'][i].documents.push(theObj)
      }

      setTimeout((function() {
        let objDiv = document.getElementById(i + '-subDocs');
        objDiv.scrollTop = objDiv.scrollHeight;
      }), 50);
    }

    this.props.updateItem(currentRec);
  }


  changeCheckBox = (e, outerKey, i, thisKey, parent, subI, thisImg) => {
    let selectedState = thisImg.chapters


    if (selectedState.includes(e.target.id)) {
      selectedState.splice(selectedState.indexOf(e.target.id), 1);
    } else {
      selectedState.push(e.target.id);
    }

    this.props.changeRecordHandler(selectedState, outerKey, i, thisKey, parent, subI, 'checks');
  };

  componentDidMount() {
    let theseChapters = this.props.currentRecord.Chapters;
    theseChapters = JSON.stringify(theseChapters);
    theseChapters = JSON.parse(theseChapters);

    this.setState({
      oldChapters: theseChapters,
    })
  }


  // Render
  // ----------------------------------------------------
  render() {
    const { currentRecord, recordChanges } = this.props;


      // {Object.keys(e).forEach(function(key) {
      //     return (
      //       <div className="inputBlock">
      //         <p>{key}</p>
      //         <input
        // type="text"
        // value={e[key]}
        // onChange={this.props.changeRecordHandler(event, 'key')}
                     // />
      //       </div>
      //     )
      // })}

    let allChapters = currentRecord['Chapters'];

    let clientClass = 'Clients';
    if (this.state.client) {clientClass='Clients isActive'}

    let projectClass = 'Projects';
    if (this.state.project) {projectClass='Projects isActive'}

    let chaptersClass = 'Chapters';
    if (this.state.chapters) {chaptersClass='Chapters isActive'}

    let imagesClass = 'Images';
    if (this.state.images) {imagesClass='Images isActive'}

    let documentsClass = 'Documents';
    if (this.state.documents) {documentsClass='Documents isActive'}


    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
    let updateDate = new Date(this.props.currentRecord['Last Updated']);
    let readableDate = monthNames[updateDate.getMonth()] + ' ' + updateDate.getDay() + ' ' + updateDate.getFullYear();

    let saveClass = 'btn disabled';
    if (recordChanges) {
      saveClass = 'btn';
    }

    let archiveClass = '';
    if (currentRecord['Archived']) {
      console.log('yo');
      archiveClass = ' isArchived';
    }

    return (

      <form className={"AdminEdit" + archiveClass}>
      {this.chOptions}
        <div className="archivedOverlay">
          <div className="inner card">
            <h3>This project has been marked completed.</h3>
            <div className="btn ghostIt" onClick={()=>this.props.saveRecord('unarchive')}>Mark Incomplete</div>
            <Link to="/admin" className="btn">Back to Admin</Link>
          </div>
        </div>
        <div className="UpdateArea">
          <div className={saveClass} onClick={()=>this.props.saveRecord('save', this.state.oldChapters)}>Save Changes</div>
          {this.props.currentId !== 'new' ? <div className='btn ghostIt' onClick={()=>this.props.saveRecord('preview')}>Preview as Client</div> : ''}
          {this.props.currentId !== 'new' ? <div className='btn ghostIt' onClick={()=>this.props.triggerSend()}>Send Email Update</div> : ''}
          {this.props.currentId !== 'new' ? <p>Last Updated: {readableDate}</p> : ''}
          {this.props.currentId !== 'new' ? <div className='btn ghostIt archive' onClick={()=>this.props.saveRecord('archive')}>Complete Project</div> : ''}

        </div>

        <section className={projectClass}>
          <div className="cardNav">
            <h2 onClick={()=>this.h2Toggle('project')}>Project</h2>
          </div>
          <div className="card" key={'project'}>
            <div className="inputBlock">
              <label>Project</label>
              <input
                type="text"
                value={currentRecord['Project']}
                onChange={(e) => this.props.changeRecordHandler(e, 'Project')}
                 />
            </div>
            <div className="inputBlock">
              <label>Location</label>
              <input
                type="text"
                value={currentRecord['Location']}
                onChange={(e) => this.props.changeRecordHandler(e, 'Location')}
                 />
            </div>
          </div>
        </section>

        <section className={clientClass}>
          <div className="cardNav">
            <h2 onClick={()=>this.h2Toggle('client')}>Cliente(s)</h2>
            <div className="btn" onClick={()=>this.addItem('client')}>Agregar +</div>
          </div>
          {currentRecord['Client Information'] ? currentRecord['Client Information'].map((e,i) => {
            return (
              <div className="card" key={'clients-' + i}>
                <div className="remove" onClick={()=>this.removeItem('Client Information',i)}><img src={exit} alt="remove" /></div>
                <div className="inputBlock">
                  <label>Name</label>
                  <input
                    type="text"
                    value={e.name}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Client Information', i, 'name')}
                     />
                </div>
                <div className="inputBlock">
                  <label>Email</label>
                  <input
                    type="email"
                    value={e.email}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Client Information', i, 'email')}
                     />
                </div>

                <div className="inputBlock">
                  <label>Password</label>
                  <input
                    type="text"
                    value={e.password}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Client Information', i, 'password')}
                     />
                </div>
              </div>
            )
          }) : ''}
        </section>

        <section className={chaptersClass}>
          <div className="cardNav">
            <h2 onClick={()=>this.h2Toggle('chapters')}>Capítulos</h2>
            <div className="btn" onClick={()=>this.addItem('chapter')}>Agregar +</div>
          </div>
          {currentRecord['Chapters'] ? currentRecord['Chapters'].map((e,i) => {
            let outerI = i;
            return (
              <div className="card" key={'chapters-' + i}>
                <div className="remove" onClick={()=>this.removeItem('Chapters',i)}><img src={exit} alt="remove" /></div>
                <div className="inputBlock">
                  <label>Name</label>
                  <input
                    type="text"
                    value={e.name}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Chapters', i, 'name')}
                     />
                </div>
                <div className="inputBlock">
                  <label>Status ({e.status}%)</label>
                  <input
                    type="range"
                    value={e.status}
                    min="0" max="100" step="5"
                    onChange={(e) => this.props.changeRecordHandler(e, 'Chapters', i, 'status')}
                     />
                </div>

                <label>Icon</label>
                <div class={'iconSelect icon-' + e.icon}>
                  <div onClick={(e)=>this.props.changeIcon('1', outerI)} className={e.icon === '1' ? 'icon isActive' : 'icon'}><img src={ch1} alt="" /></div>
                  <div onClick={(e)=>this.props.changeIcon('2', outerI)} className={e.icon === '2' ? 'icon isActive' : 'icon'}><img src={ch2} alt="" /></div>
                  <div onClick={(e)=>this.props.changeIcon('3', outerI)} className={e.icon === '3' ? 'icon isActive' : 'icon'}><img src={ch3} alt="" /></div>
                  <div onClick={(e)=>this.props.changeIcon('4', outerI)} className={e.icon === '4' ? 'icon isActive' : 'icon'}><img src={ch4} alt="" /></div>
                  <div onClick={(e)=>this.props.changeIcon('5', outerI)} className={e.icon === '5' ? 'icon isActive' : 'icon'}><img src={ch5} alt="" /></div>
                  <div onClick={(e)=>this.props.changeIcon('6', outerI)} className={e.icon === '6' ? 'icon isActive' : 'icon'}><img src={ch6} alt="" /></div>
                  <div onClick={(e)=>this.props.changeIcon('7', outerI)} className={e.icon === '7' ? 'icon isActive' : 'icon'}><img src={ch7} alt="" /></div>
                  <div onClick={(e)=>this.props.changeIcon('8', outerI)} className={e.icon === '8' ? 'icon isActive' : 'icon'}><img src={ch8} alt="" /></div>
                </div>



                <div className="cardWrapper">
                  <div className="cardNav">
                    <h3>Tasks</h3>
                    <div className="btn" onClick={()=>this.addItem('task', outerI)}>Agregar +</div>
                  </div>
                  <div className="wrapperInner" id={outerI + '-tasks'}>
                    {e.tasks ? e.tasks.map((e,key) => {
                      return (
                        <div className="card" id={outerI + '-tasks-' + key} key={outerI + '-tasks-' + key}>
                          <div className="remove" onClick={()=>this.removeItem('Chapters',key,'tasks',outerI)}><img src={exit} alt="remove" /></div>
                          <div className="inputBlock">
                            <p>Name</p>
                            <input
                              type="text"
                              value={e.name}
                              onChange={(e) => this.props.changeRecordHandler(e, 'Chapters', outerI, 'name', 'tasks', key)}
                           />
                          </div>

                          <select value={e.completed} onChange={(e) => this.props.changeRecordHandler(e, 'Chapters', outerI, 'completed', 'tasks', key)}>
                            <option value="false">Incomplete</option>
                            <option value="true">Complete</option>
                          </select>
                        </div>
                      )
                    }) : ''}
                  </div>
                </div>
              </div>
            )
          }) : ''}
        </section>

        <section className={imagesClass}>
          <div className="cardNav">
            <h2 onClick={()=>this.h2Toggle('images')}>Fotos</h2>
            <div className="btn"onClick={()=>this.addItem('image')}>Agregar +</div>
          </div>
          {currentRecord['Images'] ? currentRecord['Images'].map((e, i, allChapters) => {
            let outerI = i;
            let thisImg = e;

            if (!e.url) {
              return (
                <div className="card" key={'imgs-' + i}>
                  <div className="remove" onClick={()=>this.removeItem('Images',i)}><img src={exit} alt="remove" /></div>

                  <div className="inputBlock">
                    <label>Subir Foto</label>
                    <input
                      type="file"
                      name="file"
                      placeholder="Subir Foto"
                      onChange={(e)=>this.uploadDoc(e, i, 'image')}
                    />
                  </div>

                  <div className="inputBlock">
                    <label>Caption</label>
                    <input
                      type="text"
                      value={e.caption}
                      onChange={(e) => this.props.changeRecordHandler(e, 'Images', i, 'caption')}
                     />
                  </div>

                  <div className="inputBlock">
                    <fieldset>
                      <legend>Select Connected Chapters</legend>
                      {currentRecord['Chapters'].map((e,i) => {
                        let selectedClass = '';
                        if (thisImg.chapters.includes(e.slug)) {
                          selectedClass = 'isActive';
                        }
                        return (
                          <li
                            id={e.slug}
                            className={selectedClass}
                            onClick={(e)=>this.changeCheckBox(e, 'Images', outerI, 'chapters', undefined, undefined, thisImg)}
                           >
                             {e.name}
                           </li>
                        );
                      })}
                    </fieldset>
                  </div>
                </div>
              )
            } else {
              return (
                <div className="card" key={'imgs-' + i}>
                  <div className="remove" onClick={()=>this.removeItem('Images',i)}><img src={exit} alt="remove" /></div>

                  <img src={e.url} alt={e.caption} />
                  <div className="inputBlock">
                    <label>Caption</label>
                    <input
                      type="text"
                      value={e.caption}
                      onChange={(e) => this.props.changeRecordHandler(e, 'Images', i, 'caption')}
                     />
                  </div>

                  <div className="inputBlock">
                    <fieldset>
                      <legend>Select Connected Chapters</legend>
                      {currentRecord['Chapters'].map((e,i) => {
                        let selectedClass = '';
                        if (thisImg.chapters.includes(e.slug)) {
                          selectedClass = 'isActive';
                        }
                        return (
                          <li
                            id={e.slug}
                            className={selectedClass}
                            onClick={(e)=>this.changeCheckBox(e, 'Images', outerI, 'chapters', undefined, undefined, thisImg)}
                           >
                             {e.name}
                           </li>
                        );
                      })}
                    </fieldset>
                  </div>
                </div>
              )
            }
          }) : ''}
        </section>

        <section className={documentsClass}>
          <div className="cardNav">
            <h2 onClick={()=>this.h2Toggle('documents')}>Documentos</h2>
            <div className="btn" onClick={()=>this.addItem('document-category')}>Agregar +</div>
          </div>
          {currentRecord['Documents'] ? currentRecord['Documents'].map((e,i) => {
            let outerI = i;
            let outName = e.category;
            return (
              <div className="card" key={'docs-' + i}>
                <div className="remove" onClick={()=>this.removeItem('Documents',i,outerI)}><img src={exit} alt="remove" /></div>
                <div className="inputBlock">
                  <label>Group</label>
                  <input
                    type="text"
                    value={e.category}
                    onChange={(e) => this.props.changeRecordHandler(e, 'Documents', i, 'category')}
                     />
                </div>

                <div className="cardWrapper">
                  <div className="cardNav">
                    <h3>Documentos</h3>
                    <div className="btn" onClick={()=>this.addItem('document', outerI)}>Agregar +</div>
                  </div>
                  <div className="wrapperInner" id={outerI + '-subDocs'}>
                    {e.documents ? e.documents.map((e, key) => {
                      if (!e.url) {
                        return (
                          <div className="card" id={outerI + '-subDocs-' + key} key={outerI + '-subDocs-' + key}>
                            <div className="remove" onClick={()=>this.removeItem('Documents',key,'documents',outerI)}><img src={exit} alt="remove" /></div>
                            <h3>{outName}</h3>
                            <div className="inputBlock">
                              <label>Label</label>
                              <input
                                type="text"
                                value={e.name}
                                onChange={(e) => this.props.changeRecordHandler(e, 'Documents', outerI, 'name', 'documents', key)}
                           />
                            </div>

                            <div className="inputBlock">
                              <label>Subir Documento</label>
                              <input
                                type="file"
                                name="file"
                                placeholder="Subir Documento"
                                onChange={(e)=>this.uploadDoc(e, key, 'document', outerI)}
                              />
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="card" id={outerI + '-subDocs-' + key} key={outerI + '-subDocs-' + key}>
                            <div className="remove" onClick={()=>this.removeItem('Documents',key,'documents',outerI)}><img src={exit} alt="remove" /></div>
                            <h3>{outName}</h3>
                            <div className="inputBlock">
                              <label>Label</label>
                              <input
                                type="text"
                                value={e.name}
                                onChange={(e) => this.props.changeRecordHandler(e, 'Documents', outerI, 'name', 'documents', key)}
                           />
                            </div>
                            <div className="inputBlock">
                              <label>URL to Document</label>
                              <input
                                type="text"
                                value={e.url}
                                onChange={(e) => this.props.changeRecordHandler(e, 'Documents', outerI, 'url', 'documents', key)}
                           />
                            </div>
                          </div>
                        )
                      }
                    }) : ''}
                  </div>
                </div>

              </div>
            )
          }) : ''}
        </section>
      </form>

    );
  }

  get chOptions() {
    if (this.props.emailModal) {

      return (
        <div class="modalWrapper">
          <div class="modal card docModal">
            <h3>Which chapters have been updated?</h3>
            <a class="closeBtn" onClick={()=>this.props.triggerSend()}>Close</a>
            <hr />
            <form onSubmit={this.checkEmail}>
              <div class="inputBlock">
                {this.props.currentRecord.Chapters.length > 0 ? this.props.currentRecord.Chapters.map((e,i) => {
                  return(
                    <div className="inputBlock">
                      <label for={e.slug}>{e.name}</label>
                      <input type="checkbox" value={e.slug} name={e.slug} />
                    </div>
                  );
                }) : ''}
              </div>
              <button type="submit" className="btn isPrimary">Send Email Update</button>
            </form>
          </div>
        </div>
      );
    } else {
      return;
    }
  }

  loadingBlock() {
    return (
      <h2>Loading</h2>
    )
  }
}
