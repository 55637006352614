import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import { Base64 } from 'js-base64';

import chapters from '../assets/welcome/Login-Chapters.png';
import completed from '../assets/welcome/Login-Nivel.png';
import images from '../assets/welcome/Login-Fotos.png';
import exit from '../assets/exit.png';
import logo from '../assets/logo.png';
import ch1 from '../assets/ch/1.png';
import ch2 from '../assets/ch/2.png';
import ch3 from '../assets/ch/3.png';


export default class LoginForm extends Component {
  constructor(props) {
    super();
    this.state = {
      use: '',
      pw: '',
      checkData: [],
      users: [],
      admins: [],
      possibleLogs: [],
      type: 'login',
      currSlide: 0,
      toggleMouse: false,
    }
  }

  checkLogin = () => {
    //update this!
    let finalURL = 'https://api.airtable.com/v0/appKladEjQEUksQzf/Admins';

    return axios
      .get(finalURL)
      .then(response => {
        this.setState({
          checkData: response.data.records,
        });

        setTimeout((function() {
          console.log(this.state.checkData);
          if (this.state.checkData.filter(user => user.fields['Email'] === localStorage.admin)[0]) {
            this.props.history.push('/admin/');
          } else {
            this.loadData();
          }
        }).bind(this), 0);
      })
      .catch(error => {
        console.error("error: ", error);
        this.setState({
          error: `${error}`,
        });
      });
  }

  loadData = () => {
    this.setState({
      loading: true
    });

    //initial load
    setTimeout((function() {
      let projURL = 'https://api.airtable.com/v0/appKladEjQEUksQzf/Projects';


      console.log(projURL);
      console.log('loadData()');
      return axios
      .get(projURL)
      .then(response => {
        this.setState({
          users: response.data.records,
          //put it here
          loading: false,
          error: false,
          loadingMore: true,
          dataOffset: response.data.offset,
        });
        setTimeout((function() {
          this.setState({
            loadingMore: false,
          });
          document.title = "CROD Construcciones Client Portal";


          setTimeout((function() {
            let adminURL = 'https://api.airtable.com/v0/appKladEjQEUksQzf/Admins';


            console.log('loadData()');
            return axios
            .get(adminURL)
            .then(response => {
              this.setState({
                admins: response.data.records,
                //put it here
                loading: false,
                error: false,
                loadingMore: true,
                dataOffset: response.data.offset,
              });
              setTimeout((function() {
                this.setState({
                  loadingMore: false,
                });

                this.processData();
              }).bind(this), 10);
            })
            .catch(error => {
              console.error("error: ", error);
              this.setState({
                error: `${error}`,
                loading: false,
              });
            });
          }).bind(this), 10);
        }).bind(this), 10);
      })
      .catch(error => {
        console.error("error: ", error);
        this.setState({
          error: `${error}`,
          loading: false,
        });
      });
    }).bind(this), 10);
  };

  processData = () => {
    let possibleLogs = [];


    for (var i in this.state.users) {
      if (this.state.users[i].fields['Client Information']) {
        let propedObj = JSON.parse('[' + this.state.users[i].fields['Client Information'] + ']');

        for (var x in propedObj) {
          propedObj[x].id = this.state.users[i].id;
          possibleLogs.push(propedObj[x]);
        }
      }
    }

    for (var aI in this.state.admins) {
      let propedObj = this.state.admins[aI].fields;
      propedObj.isAdmin = true;

      possibleLogs.push(propedObj);
    }


    console.log(possibleLogs);



    this.setState({
      possibleLogs: possibleLogs
    })
  }

  tryLogin = (e, type) => {
    let user = '';
    let pass = '';
    if (type === 'ref') {
      user = e.email;
      pass = e.access;
    } else {
      e.preventDefault();
      user = document.getElementById('email').value;
      pass = document.getElementById('password').value;
    }

    console.log(user);
    console.log(this.state.possibleLogs);

    let id = '';
    let matchUser = false;
    let matchPass = false;
    let isAdmin = false

    for (var i in this.state.possibleLogs) {
      if (this.state.possibleLogs[i].Email) { //is admin
        if (user === this.state.possibleLogs[i].Email) {
          matchUser = true;
          isAdmin = true;
          var decode = Base64.decode(this.state.possibleLogs[i].Password);
          if (pass === decode) {
            matchPass = true;
          }
        }
      } else if (this.state.possibleLogs[i].email) { //is user
        if (user === this.state.possibleLogs[i].email) {
          matchUser = true;
          var userDecode = Base64.decode(this.state.possibleLogs[i].password);

          console.log(userDecode + ' userDecode');
          console.log(pass + ' pass');
          if (pass === userDecode) {
            matchPass = true;
            id = this.state.possibleLogs[i].id;
          }
        }
      }
    }


    if (matchUser) {
      if (matchPass) {
        if (isAdmin) {
          localStorage.setItem('admin', user)
          this.props.history.push('/admin/');
        } else {
          localStorage.setItem('user', user)
          this.props.history.push('/cliente/' + id);
        }

      } else {
        this.setState({
          theError: 'Incorrect Password!'
        });
      }
    } else {
      this.setState({
        theError: 'No user with that Email'
      });
    }

    // racosta@crodconstrucciones.com
  }


  toggleMouse = () => {
    if (this.state.mouseOn) {
      this.setState({
        mouseOn: false,
      })
    } else {
      this.setState({
        mouseOn: true,
      })
    }
  }

  componentDidMount() {
    this.checkLogin();

    if (this.props.location.search) {
      console.log('has search - ' + this.props.location.search);
      let loginObj = {
        email: this.props.location.search.split('email=')[1].split('&')[0],
        access: this.props.location.search.split('access=')[1]
      }

      console.log(loginObj);

      let checkIfRef = function() {
        if (this.state.possibleLogs.length > 0) {
          console.log('tryinglog');
          this.tryLogin(loginObj, 'ref')
        } else {
          setTimeout((function() {
            checkIfRef();
          }), 100);
        }
      }.bind(this)


      if (loginObj.email && loginObj.access) {
        checkIfRef();
      }

    }


    let runSlideChange = function() {
      let currSlide = this.state.currSlide;
      if (!this.state.mouseOn) {
        if (this.state.currSlide === 2) {
          currSlide = 0;
        } else {
          currSlide ++;
        }

        this.setState({
          currSlide: currSlide
        })
      }
      setTimeout((function() {
        runSlideChange();
      }), 5000);
    }.bind(this)
    setTimeout((function() {
      runSlideChange();
    }), 5000);

  }
  // Render
  // ----------------------------------------------------
  render() {



    return (
      <div className="Welcome">
        <div className="login card">
          <a href="https://crodconstrucciones.com/"><img src={logo} /> <span>Back to Website</span></a>
          <div className="inner">
            <h2>Welcome</h2>
            <p>Enter email + password</p>
            <p className="theError">{this.state.theError}</p>

            <form onSubmit={this.tryLogin}>
              <input type="email" id="email" placeholder="email" />
              <input type="password" id="password" placeholder="****" />
              <input type="submit" className="btn" placeholder="Submit" />
            </form>
          </div>
        </div>

        <div className="loginExplain" onMouseLeave={()=>this.toggleMouse()}  onMouseEnter={()=>this.toggleMouse()}>
          <div className="loginItem isActive">
            <div className="inner">
              <img src={images} />

              <div className="card">
                <img src={ch3} />
                <p>Ver fotos durante la renovación.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }

}
