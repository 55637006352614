import React, { Component } from 'react';

class Featured extends Component {
  // Render
  // ----------------------------------------------------
  render() {
    const { currentRecord } = this.props;

    let completedChapters = [];
    for (var i in currentRecord['Chapters']) {
      if (currentRecord['Chapters'][i].status === '100') {
        completedChapters.push(currentRecord['Chapters'][i]);
      }
    }
    let mostRecentCompleted = completedChapters.sort((a, b) => (new Date(a.updated) < new Date(b.updated)) ? 1 : -1)[0];


    let sortedImgs = [];
    for (var x in currentRecord['Images']) {
      for (var z in currentRecord['Images'][x].chapters) {
        if (mostRecentCompleted) {
          if (mostRecentCompleted.slug === currentRecord['Images'][x].chapters[z]) {
            sortedImgs.push(currentRecord['Images'][x]);
          }
        }
      }
    }
    let recentImg = sortedImgs.sort((a, b) => (new Date(a.date) < new Date(b.date)) ? 1 : -1)[0];


    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let updateDate = '';
    let readableDate = '';
    if (mostRecentCompleted) {
      updateDate = new Date(mostRecentCompleted.updated);
      readableDate = monthNames[updateDate.getMonth()] + ' ' + updateDate.getDay() + ' ' + updateDate.getFullYear();
      return (
        <div className={recentImg ? 'wrapper well Featured' : 'wrapper well Featured isHidden'}>
          <p>Recientemente Concluido</p>
          <h2>{mostRecentCompleted.name}</h2>

          <div className="featStack">
            {recentImg ? <div className="imgArea"><img src={recentImg.url} alt={recentImg.caption} /></div> : ''}

            <div className="card stackCard">
              <h3>Concluido {readableDate}</h3>
                <div className="taskList">
                  {mostRecentCompleted.tasks ? mostRecentCompleted.tasks.map((e,key) => {
                    let taskClass="task";
                    if (e.completed) {  taskClass+= ' completed'; }
                    return (
                        <div className={taskClass} key={'tasks-' + key}>
                          <p>{e.name}</p>
                        </div>
                    )
                  }) : ''}
                </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <br />
      )
    }
  }
}

export default Featured;
