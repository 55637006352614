import React, { Component } from 'react';
import '../styles/App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
// import bcrypt from 'bcrypt';
import { Base64 } from 'js-base64';
import exit from '../assets/exit.png';
import trash from '../assets/trash.png';
import download from '../assets/download.png';

import EditView from './EditView';
import PortalView from '../Portal/PortalView';
import ListView from './ListView';
import Navbar from '../Globals/Navbar';

let finalURL;

export default class AdminPanel extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      error: "",
      data: null,
      dataURL: 'https://api.airtable.com/v0/',
      baseId: 'appKladEjQEUksQzf',
      listView: 'view=All',
      currentTable: 'Projects',
      currentRecord: [],
      currentRecordIndex: [],
      currentId: [],
      fallbackRecord: [],
      recordView: false,
      activeModal: false,
      modalType: '',
      dataOffset: '',
      userName: '',
      newRecord: false,
      recordChanges: false,
      listIsVisible: props.recordId == null,
      preview: false,
      isAdmin: true,
      docModal: false,
      documents: [],
      emailModal: false,
    }
  }



  // handleSubmit = () => {
  //
  //   let emailData = {
  //     template_id:"[template_id]"
  //
  //     from: 'racosta@crodconstrucciones.com', // Change to your verified sender
  //     subject: 'CROD Construcciones Projecto Update',
  //     text: 'and easy to do anywhere, even with Node.js',
  //     personalizations:[
  //       {
  //         to: this.props.currentRecord['Client Information'][0].email
  //         dynamic_template_data: {
  //           "project": this.props.currentRecord['Project'],
  //           "featured": this.props.currentRecord['Images'][this.props.currentRecord['Images'].length - 1].url,
  //           "id": this.props.currentId,
  //         }
  //       }
  //     ],
  //   };
  //
  //   fetch('/api/form-submit-url', {
  //     method: 'POST',
  //     body: data,
  //   });
  // }


  componentWillUpdate = (nextProps, nextState) => {
    if (this.state.loading && !nextState.loading) {
      if (this.props.recordId != null) {
        if (this.props.recordId === 'new') {
          console.log('new-1');
            let today = new Date()
            today = (today.getMonth()+1) + '/' + today.getDate() + '/' + today.getFullYear();
            let emptyRecord = {
              "Location":"",
              "Project":"",
              "Client Information":[],
              "Documents":[],
              "Chapters":[],
              "Images":[],
              "Last Updated":today
            }

            this.setState({
              recordView: true,
              currentRecord: emptyRecord,
              currentId: 'new',
              // noteCharacters: record['Notes'].length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            })

            console.log(emptyRecord);
        } else if (this.props.recordId === 'documents') {
          console.log('docs');

          finalURL = this.state.dataURL + this.state.baseId + '/CROD Documents/';
          return axios
            .get(finalURL)
            .then(response => {
              console.log(response);
              this.setState({
                loading: false,
                error: false,
                documents: response.data.records,
                recordView: false,
                docModal: true,
                currentRecord: undefined,
              });
            })
            .catch(error => {
              console.error("error: ", error);
              this.setState({
                error: `${error}`,
                loading: false,
              });
            });
        } else {
          console.log('yo-1');
          if (nextState.data != null && nextState.data.filter(e => e.id === this.props.recordId)[0]) {
            if (this.props.location.pathname.includes('/view')) {
              this.setState({
                preview: true,
              })
            }

            const record = nextState.data.filter(e => e.id === this.props.recordId)[0].fields;
            if (record) {
              Object.keys(record).forEach(function(key) {
                if (typeof record[key] === 'string' && record[key].indexOf('{') !== -1) {
                  record[key] = JSON.parse('[' + record[key] + ']');
                }
              });
            }




            for (var i in record['Client Information']) {
              if (record['Client Information'][i].password) {
                var decode = Base64.decode(record['Client Information'][i].password);
                record['Client Information'][i].password = decode;
              }
            }

            setTimeout((function() {
              this.setState({
                recordView: true,
                currentRecord: record,
                currentId: nextState.data.filter(e => e.id === this.props.recordId)[0].id,
                // noteCharacters: record['Notes'].length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              })
            }).bind(this), 20);
          } else {
            finalURL = this.state.dataURL + this.state.baseId + '/' + this.state.currentTable + '/' + this.props.recordId;
            return axios
              .get(finalURL)
              .then(response => {
                this.setState({
                  recordView: true,
                  loading: false,
                  error: false,
                  // noteCharacters: response.data.fields['Notes'].length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  currentRecord: response.data.fields,
                });
              })
              .catch(error => {
                this.setState({
                  error: true,
                  message: "404, no record",
                  loading: false,
                });
              });
          }
        }
      } else {
        console.log('yo-3');
        finalURL = this.state.dataURL + this.state.baseId + '/' + this.state.currentTable + '/';
        return axios
          .get(finalURL)
          .then(response => {
            console.log(response);
            this.setState({
              recordView: false,
              loading: false,
              error: false,
              data: response.data.records,
              currentRecord: undefined,
            });
          })
          .catch(error => {
            console.error("error: ", error);
            this.setState({
              error: `${error}`,
              loading: false,
            });
          });
      }
    }
  }

  updateItem = e => {
    this.setState({
      currentRecord: e,
      recordChanges: true,
    })
  }

  addingDoc = (file, i, type, outerI) => {
    let currentRecordState = this.state.currentRecord;

    if (type === 'image') {
      currentRecordState['Images'][i].url = file;
    } else {
      currentRecordState['Documents'][outerI].documents[i].url = file;
    }

    this.setState({
      currentRecord: currentRecordState,
    })
  }


  navExit = () => {
    if (this.state.recordChanges) {
      this.toggleModal();
    } else {
      this.props.history.push('/admin/');
    }
  }

  toggleModal = () => {
    if (this.state.showConfirm) {
      this.setState({
        showConfirm: false,
      })
    } else {
      this.setState({
        showConfirm: true,
      })
    }
  }

  saveRecord = (type, oldChapters) => {

    if (this.state.recordChanges || type === 'archive' || type === 'unarchive') {
      let pushRecord = this.state.currentRecord;
      let pushRecordId = this.props.recordId;



      for (var i in pushRecord['Client Information']) {
        if (pushRecord['Client Information'][i].password) {
          var encode = Base64.encode(pushRecord['Client Information'][i].password);
          pushRecord['Client Information'][i].password = encode;
        }
      }

      if (type === 'archive') {
        pushRecord['Archived'] = true;
      }
      if (type === 'unarchive') {
        pushRecord['Archived'] = undefined;
      }


      let finalPush = {"fields": pushRecord}



      console.log(finalPush);


      Object.keys(pushRecord).forEach(function(key) {
        if (typeof pushRecord[key] === 'object') {
          pushRecord[key] = JSON.stringify(pushRecord[key]);
          pushRecord[key] = pushRecord[key].substr(1);
          pushRecord[key] = pushRecord[key].substr(0, pushRecord[key].length - 1);
        }
      });

      if (this.state.currentId === 'new') {
        axios
        .post(this.state.dataURL + this.state.baseId + '/' + this.state.currentTable, finalPush)
        .then(response => {
          Object.keys(pushRecord).forEach(function(key) {
            if (typeof pushRecord[key] === 'string' && pushRecord[key].indexOf('{') !== -1) {
              pushRecord[key] = JSON.parse('[' + pushRecord[key] + ']');
            }
          });


          for (var i in pushRecord['Client Information']) {
            if (pushRecord['Client Information'][i].password) {
              var decode = Base64.decode(pushRecord['Client Information'][i].password);
              pushRecord['Client Information'][i].password = decode;
            }
          }

          this.setState({
            currentId: response.data.id,
          })
          alert('New record created!');
          //need to move to the returned id!!!!
          setTimeout((function() {
            this.props.history.push('/admin/' + response.data.id);
          }).bind(this), 10);
        });
      } else {
        axios
        .put(this.state.dataURL + this.state.baseId + '/' + this.state.currentTable + '/' + pushRecordId, finalPush)
        .then(response => {
          Object.keys(pushRecord).forEach(function(key) {
            if (typeof pushRecord[key] === 'string' && pushRecord[key].indexOf('{') !== -1) {
              pushRecord[key] = JSON.parse('[' + pushRecord[key] + ']');
            }
          });

          for (var i in pushRecord['Client Information']) {
            if (pushRecord['Client Information'][i].password) {
              var decode = Base64.decode(pushRecord['Client Information'][i].password);
              pushRecord['Client Information'][i].password = decode;
            }
          }

          this.setState({
            recordChanges: false,
          })
          if (type === 'preview') {
            this.props.history.push('/admin/' + this.state.currentId + '/view');
          } else {
            alert('Record Saved');
          }
        });
      }
    } else {
      if (type === 'preview') {
        this.props.history.push('/admin/' + this.state.currentId + '/view');
      }
    }
  }

  changeRecordHandler = (e, outerKey, i, thisKey, parent, subI, type) => {
    let currentRecordState = this.state.currentRecord;


    if (thisKey === 'name' && outerKey === 'Chapters' && !parent) {
      currentRecordState[outerKey][i].slug = e.target.value.replace(/ /g, '_').toLowerCase();
    }

    if (outerKey === 'Chapters') {
      if (!parent) { //is a child
        let today = new Date()
        today = (today.getMonth()+1) + '/' + today.getDate() + '/' + today.getFullYear();
        currentRecordState[outerKey][i].updated = today;
      }
    }

    if (parent) { //is a child

      currentRecordState[outerKey][i][parent][subI][thisKey] = e.target.value;
    } else if( type === 'checks' ) {
      console.log(type);
      currentRecordState[outerKey][i][thisKey] = e;
    } else if (thisKey) {
      currentRecordState[outerKey][i][thisKey] = e.target.value;
    } else { //is project info
      currentRecordState[outerKey] = e.target.value;
    }

      this.setState({
        currentRecord: currentRecordState,
        recordChanges: true,
      })
  };



  loadData = () => {
    this.setState({
      loading: true
    });

    //initial load
    setTimeout((function() {
      finalURL = this.state.dataURL + this.state.baseId + '/' + this.state.currentTable + '?' + this.state.listView;

      if (this.state.dataOffset !== '') {
        finalURL = finalURL + '&offset=' + this.state.dataOffset;
      }

      console.log('loadData()');
      return axios
      .get(finalURL)
      .then(response => {
        this.setState({
          data: response.data.records,
          //put it here
          loading: false,
          error: false,
          loadingMore: true,
          dataOffset: response.data.offset,
        });
        setTimeout((function() {
          this.setState({
            loadingMore: false,
          });

          if (this.state.recordView) {
            if (this.state.preview) {
              document.title = "Previewing " + this.state.currentRecord['Project'] + " | CROD Construcciones"
            } else {
              document.title = "Editing " + this.state.currentRecord['Project'] + " | CROD Construcciones"
            }
          } else {
            document.title = "Admin Panel | CROD Construcciones";
          }

        }).bind(this), 100);
      })
      .catch(error => {
        console.error("error: ", error);
        this.setState({
          error: `${error}`,
          loading: false,
        });
      });
    }).bind(this), 10);
  };


  uploadDoc = (e, fields, i, id) => {
    const uploadDoc = async e => {
      const files = e.target.files;
      const data = new FormData();
      data.append('file', files[0]);
      data.append('upload_preset', 'crodadmin');
      const res = await fetch(
        'https://api.cloudinary.com/v1_1/radcollab/image/upload',
        {
          method: 'POST',
          body: data,
        }
      );
      const file = await res.json();

      fields['URL'] = file.secure_url;

      setTimeout((function() {
        moveToRec();
      }), 20);
    }
    uploadDoc(e);

    let moveToRec = function(i) {
      this.saveDocument(fields, 'upload', i, id);
    }.bind(this);
  }

  saveDocument = (e, type, i, id) => {
    console.log(e);
    let pushRecord;
    let theDocs = this.state.documents;

    if (type === 'new') {
      let today = new Date()
      today = (today.getMonth()+1) + '/' + today.getDate() + '/' + today.getFullYear();
      pushRecord = {
        "Name":"",
        "URL":"",
        "Upload Date":today
      }
    } else if (type === 'remove') {
      theDocs = theDocs.filter(e => e.id !== id);
    } else if (type === 'update') {
      pushRecord = e;
    } else {
      pushRecord = e;
    }

    let finalPush = {"fields": pushRecord}

    if (type === 'new') {
      axios
      .post(this.state.dataURL + this.state.baseId + '/CROD Documents', finalPush)
      .then(response => {
        theDocs.push(response.data)

        this.setState({
          documents: theDocs,
        })
      });
    } else if (type === 'remove') {
      axios
      .delete(this.state.dataURL + this.state.baseId + '/CROD Documents/' + id)
      .then(response => {
        console.log(response);
        this.setState({
          documents: theDocs,
        })
      });
    } else {
      axios
      .put(this.state.dataURL + this.state.baseId + '/CROD Documents/' + id, finalPush)
      .then(response => {
        console.log(response);
        this.setState({
          documents: theDocs,
        })
      });
    }
  }

  changeDocName = (e, i) => {
    let theDocState = this.state.documents;
    theDocState[i].fields['Name'] = e.target.value;

    this.setState({
      documents: theDocState
    });
  }


  logoutHandler = () => {
    sessionStorage.clear();
    localStorage.clear();

    this.props.history.push('/');
  }



  checkLogin = () => {
    //update this!
    let finalURL = this.state.dataURL + this.state.baseId + '/Admins';
    console.log(finalURL);

    return axios
      .get(finalURL)
      .then(response => {
        this.setState({
          checkData: response.data.records,
        });


        setTimeout((function() {
          console.log(this.state.checkData);
          let userRecord;
          if (this.state.checkData.filter(user => user.fields['Email'] === localStorage.admin)[0]) {
            userRecord = this.state.checkData.filter(user => user.fields['Email'] === localStorage.admin)[0];
            console.log(userRecord);
            this.loadData();
          } else {
            this.logoutHandler();
          }
        }).bind(this), 0);
      })
      .catch(error => {
        console.error("error: ", error);
        this.setState({
          error: `${error}`,
        });
      });
  }

  changeIcon = (e, i) => {
    console.log(e);
    console.log(i);

    let thisRec = this.state.currentRecord;
    thisRec['Chapters'][i].icon = e;

    this.setState({
      currentRecord: thisRec,
      recordChanges: true,
    })
  }



  triggerSend = () => {
    if (this.state.emailModal) {
      this.setState({
        emailModal: false
      })
    } else {
      this.setState({
        emailModal: true,
      })
    }
  }

  callAPI = (changed) => {
    console.log('called');

    let fetchURL = 'https://crod-send.herokuapp.com/sendUpdate';
    fetchURL += '?id=' + this.state.currentId;
    fetchURL += '&project=' + this.state.currentRecord.Project;


    let imageURL = '';
    if (this.state.currentRecord.Images) {
      imageURL = this.state.currentRecord.Images[this.state.currentRecord.Images.length - 1].url;
      if (imageURL.includes('upload')) {
        imageURL = imageURL.split('upload')[0] + 'upload/c_scale,w_600' + imageURL.split('upload')[1];
      }
      fetchURL += '&photo=' + imageURL;
    }
    fetchURL += '&changed=' + JSON.stringify(changed);
    fetchURL += "&emails=" + this.state.currentRecord['Client Information'][0].email;

    // let emails = [];
    // for (var i in this.state.currentRecord['Client Information']) {
    //   let obj = {};
    //   obj.email = this.state.currentRecord['Client Information'][i].email;
    //   emails.push(obj);
    // }
    // fetchURL += '&email=' + JSON.stringify(emails);
    //
    // console.log(fetchURL);


    fetch(encodeURI(fetchURL))
    .then(res  => {
      this.setState({
        emailModal: false,
      })
      alert('Email has been sent to ' + this.state.currentRecord['Client Information'][0].email);
    });
  }



  componentDidMount() {
    this.checkLogin();
    // if (localStorage.getItem('isLogged')  !== 'true' || localStorage.getItem('isAdmin')  !== 'true') {
    //   this.props.history.push('/login');
    // } else {
    // }

    // this.loadData();
  }


    // Render
    // ----------------------------------------------------
    render() {
      return (
        <div className="AdminPanel">
          {this.docUploads}
          <Navbar
            currentRecord={this.state.currentRecord}
            recordView={this.state.recordView}
            closeRecordHandler={this.closeRecordHandler}
            currentId= {this.state.currentId}
            recordChanges= {this.state.recordChanges}
            preview= {this.state.preview}
            isAdmin= {this.state.isAdmin}
            navExit={this.navExit}
            logoutHandler={this.logoutHandler}
          />

          {this.viewType}
          {this.state.showConfirm ? this.confirmDialogue : ''}
        </div>
      );
    }

    get docUploads() {
      if (this.state.docModal) {
        return (
          <div className="modalWrapper">
            <div className="modal card docModal">
              <h3>Manage Documents</h3>
              <Link to={'/admin'}>
                <div className="btn navIcon"><img src={exit} alt="exit" /></div>
              </Link>

              <div className="docList">
                {this.state.documents.length > 0 ? this.state.documents.map((e,i) => {
                  let theURL;


                  if (e.fields) {
                    if (e.fields['URL']) {
                      theURL = e.fields['URL'];
                      if (!theURL.includes('http')) {
                        theURL = 'https://' + theURL;
                      }
                    }
                    if (e.fields['URL'] != undefined) {
                      return (
                        <div className="ListItem card">
                          <span className="download trash" onClick={()=>this.saveDocument(e.fields, 'remove', i, e.id)}><img src={trash} alt="remove" /></span>
                          <label>Document Name</label>
                          <input value={e.fields['Name']} onBlur={() => this.saveDocument(e.fields, 'update', i, e.id) } onChange={(e)=>this.changeDocName(e, i)} type="text" />
                          <a className="download" href={theURL} rel="noopener noreferrer" target="_blank"><img src={download} alt="download" /></a>
                        </div>
                      )
                    } else {
                      let theFields = e.fields;
                      let theID = e.id;
                      return (
                        <div className="ListItem card">
                          <span className="download trash" onClick={()=>this.saveDocument(e.fields, 'remove', i, e.id)}><img src={trash} alt="remove" /></span>
                          <label>Document Name</label>
                          <input value={e.fields['Name']} onBlur={() => this.saveDocument(e.fields, 'update', i, e.id) } onChange={(e)=>this.changeDocName(e, i)} type="text" />
                          <div className="inputBlock">
                            <label>Subir Documento</label>
                            <input
                              type="file"
                              name="file"
                              placeholder="Subir Documento"
                              onChange={(e)=>this.uploadDoc(e, theFields, i, theID)}
                            />
                          </div>
                        </div>
                      )
                    }
                  } else {
                    return (
                      <h3>Loading...</h3>
                    )
                  }
                }) : ''}

                <div className="btn" onClick={(e)=>this.saveDocument(e, 'new')}>Add Document</div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <h3>Loading...</h3>
        )
      }
    }

    get confirmDialogue() {
      return (
        <div className="modal card">
          <h3>There are unsaved changes. Continue without saving?</h3>
          <div className="btn ghostIt" onClick={()=>this.toggleModal()}>Cancel</div>
          <Link to={'/admin/'} className="btn">Leave without saving</Link>
        </div>
      );
    }

    get viewType() {
      if (this.state.recordView) {
        if (this.state.preview) {
          return (
            <PortalView
              currentRecord={this.state.currentRecord}
              recordView={this.state.recordView}
              closeRecordHandler={this.closeRecordHandler}
              currentId= {this.state.currentId}
              recordChanges= {this.state.recordChanges}
              changeRecordHandler={this.changeRecordHandler}
              updateItem={this.updateItem}
              data={this.state.data}
              saveRecord={this.saveRecord}
              addingDoc={this.addingDoc}
              isAdmin= {this.state.isAdmin}
            />
          );
        } else {
          return (
            <EditView
              currentRecord={this.state.currentRecord}
              recordView={this.state.recordView}
              closeRecordHandler={this.closeRecordHandler}
              currentId= {this.state.currentId}
              recordChanges= {this.state.recordChanges}
              changeRecordHandler={this.changeRecordHandler}
              updateItem={this.updateItem}
              data={this.state.data}
              saveRecord={this.saveRecord}
              addingDoc={this.addingDoc}
              changeIcon={this.changeIcon}
              handleSubmit={this.handleSubmit}
              callAPI={this.callAPI}
              triggerSend={this.triggerSend}
              emailModal={this.state.emailModal}
            />
          );
        }
      } else if (this.state.error) {
        return (
          <div className="error">
            <h1>{this.state.message}</h1>
            <p>Click the x in the top left</p>
          </div>
        );
      } else {
        return (
          <ListView
            data={this.state.data}
          />
        );
      }
    }
  }
