import React, { Component } from 'react';

import docIcon from '../assets/document.png';
import download from '../assets/download.png';
class CrodDocuments extends Component {
  // Render
  // ----------------------------------------------------
  render() {
    const { documents } = this.props;

    if (document.getElementById('img-0')) {
      document.getElementById('img-0').getElementsByTagName('img')[0].onload = function() {
        this.gridLayout()
      }.bind(this);
    }

    return (
      <div className='wrapper well Documents'>
        <h3>CROD Documentos</h3>
        <div className="container">
          {documents ? documents.map((e,i) => {
            return (
              <div className="card image" id={'docGroup-' + i}>
                <div className="cardTop crodDoc">
                  <img src={docIcon} alt={e.fields.Name + ' icon'} />
                  <h3>{e.fields.Name}</h3>

                  <div className="downloadCorner">
                    <a href={e.fields.URL} target="_blank" rel="noopener noreferrer">
                      <img src={download} alt="download" />
                    </a>
                  </div>

                </div>
              </div>
            );
          }) : ''}
        </div>
      </div>
    );
  }
}

export default CrodDocuments;
