import React, { Component } from 'react';
import exit from '../assets/exit-black.png';
import Isotope from 'isotope-layout';


import ch1 from '../assets/ch/1.png';
import ch2 from '../assets/ch/2.png';
import ch3 from '../assets/ch/3.png';
import ch4 from '../assets/ch/4.png';
import ch5 from '../assets/ch/5.png';
import ch6 from '../assets/ch/6.png';
import ch7 from '../assets/ch/7.png';
import ch8 from '../assets/ch/8.png';

const isMobile = window.innerWidth < 480;
class Status extends Component {

  openTasks = e => {
    let theId = e + '-tasks';
    let theTrigger = e + '-trigger';


    if (document.getElementById(theId).className.includes(' isActive')) {
      document.getElementById(theId).className = 'taskList';

      if (isMobile) {
        document.getElementById(theTrigger).innerHTML = "+";
      } else {
        document.getElementById(theTrigger).innerHTML = "Ver Tareas";
      }
    } else {
      document.getElementById(theId).className += ' isActive';

      if (isMobile) {
        document.getElementById(theTrigger).innerHTML = "-";
      } else {
        document.getElementById(theTrigger).innerHTML = "Cerrar Tareas";
      }
    }

    this.gridLayout()
  }
  // Render
  // ----------------------------------------------------
  render() {
    const { currentRecord } = this.props;
    this.gridLayout()

    return (
      <div className='wrapper well--l Status'>
        <h3>Nivel del proyecto</h3>

        <div className="container">

          {currentRecord['Chapters'].map((e,i) => {
            let outerI = i
            let cardClass = "card chapter";
            if (e.status === '100') {
              cardClass += ' isCompleted';
            }



            let iconURL = '';
            if (e.icon === 0 || e.icon === '0') {
            } else if (e.icon === 1 || e.icon === '1') {
              iconURL = ch1;
            } else if (e.icon === 2 || e.icon === '2') {
              iconURL = ch2;
            } else if (e.icon === 3 || e.icon === '3') {
              iconURL = ch3;
            } else if (e.icon === 4 || e.icon === '4') {
              iconURL = ch4;
            } else if (e.icon === 5 || e.icon === '5') {
              iconURL = ch5;
            } else if (e.icon === 6 || e.icon === '6') {
              iconURL = ch6;
            } else if (e.icon === 7 || e.icon === '7') {
              iconURL = ch7;
            } else if (e.icon === 8 || e.icon === '8') {
              iconURL = ch8;
            }
            return (
              <div className={cardClass} key={e.slug}>
                <div className="cardTop">










                  <img src={iconURL} alt={e.name + ' icon'} />
                  <h3>{e.name}</h3>
                </div>
                <p>{e.status}% concluido</p>
                <div className="statusBar">
                  <div className="inner" style={{width: e.status + '%'}}></div>
                </div>


                <div className="taskList" id={e.slug + '-tasks'}>
                  {e.tasks ? e.tasks.map((e,key) => {
                    let taskClass="task";
                    if (e.completed) {  taskClass+= ' completed'; }
                    return (
                      <div className={taskClass} key={outerI + '-tasks-' + key}>
                        <p>{e.name}</p>
                      </div>
                    )
                  }) : ''}
                </div>

                {e.tasks ? <div className="viewTasks" id={e.slug + '-trigger'} onClick={()=>this.openTasks(e.slug)}>{isMobile ? '+' : 'Ver Tereas'}</div> : ''}
              </div>
            )
          })}
        </div>
      </div>
    );
  }
  gridLayout() {
    setTimeout(function(){
      var elem = document.querySelector('.Status .container');
      var iso = new Isotope( elem, {itemSelector: '.chapter'});
    }, 100);
  }
  gridDestroy() {
    var elem = document.querySelector('.Status .container');
    var iso = new Isotope( elem, {itemSelector: '.chapter'});
    iso.destroy()
  }
}

export default Status;
