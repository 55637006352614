import React, { Component } from 'react';
import Isotope from 'isotope-layout';

class Images extends Component {
  // Render
  // ----------------------------------------------------
  render() {
    const { currentRecord } = this.props;

    setTimeout(function(){
      if (document.getElementById('img-0')) {
        document.getElementById('img-0').getElementsByTagName('img')[0].onload = function() {
          this.gridLayout()
        }.bind(this);
      }
    }.bind(this), 100);

    return (
      <div className='wrapper well Images'>
        <h3>Fotos</h3>
        <div className="container">
          {currentRecord['Images'].map((e,i) => {
            return (
              <div className="card image" id={'img-' + i}>
                <img src={e.url} alt={e.caption} />
                <p>{e.caption}</p>

                <div className="tags">
                  {e.chapters ? e.chapters.map((e,i) => {
                    return (
                      <span> {e}, </span>
                    );
                  }) : ''}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  gridLayout() {
    setTimeout(function(){
      var elem = document.querySelector('.Images .container');
      var iso = new Isotope( elem, {itemSelector: '.image'});
    }, 100);
  }
  gridDestroy() {
    var elem = document.querySelector('.Images .container');
    var iso = new Isotope( elem, {itemSelector: '.image'});
    iso.destroy()
  }
}

export default Images;
