import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import exit from '../assets/exit.png';



class Navbar extends Component {
  // Render
  // ----------------------------------------------------
  render() {
    const { currentRecord, currentId } = this.props;

    if (currentRecord !== undefined) {
      if (this.props.preview) {
        return (
          <div className="Navbar previewNav">
            <Link to={'/admin'}>
              <div className="btn navIcon"><img src={exit} alt="exit" /></div>
            </Link>
            <h2>Previewing as Client</h2>
            <Link to={'/admin/' + currentId} className="btn pullRight">Edit</Link>
          </div>
        );
      } else {
        return (
          <div className="Navbar">
            <div className="btn navIcon" onClick={()=>this.props.navExit()}><img src={exit} alt="exit" /></div>
            <h2>{currentRecord['Project']}</h2>
          </div>
        );
      }
    } else {
      return (
        <div className="Navbar listNav">
          <h2>Select Project to Update</h2>
          <Link to={'/admin/new'} className="btn pullRight">New Project</Link>
          <Link to={'/admin/documents'} className="btn pullRight ghostIt">Documents</Link>
          <div onClick={()=>this.props.logoutHandler()} className="logout">Logout</div>
        </div>
      );
    }
  }
}

export default Navbar;
