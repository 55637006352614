import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class ListView extends Component {
  constructor(props) {
    super();
    this.state = {
      ongoing: [],
      completed: [],
    }
  }

  splitUp = () => {
    console.log(this.props.data);

    let ongoingList = [];
    let completedList = [];
    for (var i in this.props.data) {
      if (this.props.data[i].fields['Archived']) {
        completedList.push(this.props.data[i])
      } else {
        ongoingList.push(this.props.data[i])
      }
    }

    this.setState({
      ongoing: ongoingList,
      completed: completedList,
    })
  }

  componentDidMount() {
    let splitThem = function() {
      console.log('loadAllData');
      if (this.props.data) {
        this.splitUp();
      } else {
        setTimeout((function() {
          splitThem();
        }).bind(this), 200);
      }
    }.bind(this)
    splitThem(); //run on load
  }

  // Render
  // ----------------------------------------------------
  render() {
    const { data } = this.props;
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return (

      <div className="ListView">

        <div className="ongoingList">
          {this.state.ongoing.length > 0 ? <h3>Ongoing</h3> : ''}
          {this.state.ongoing ? this.state.ongoing.map((e,i) => {

            let updateDate = new Date(e.fields['Last Updated']);
            let readableDate = monthNames[updateDate.getMonth()] + ' ' + updateDate.getDay() + ' ' + updateDate.getFullYear();
            return (
              <div className="ListItem card">
                <h3>{e.fields['Project']}</h3>
                <p>Updated {readableDate}</p> <br />
                <Link className="btn" to={'/admin/' + e.id}>Edit</Link>
                <Link className="btn ghostIt" to={'/admin/' + e.id + '/view/'}>Preview</Link>
              </div>
            )
          }) : ''}
        </div>

        <div className="ongoingList">
          {this.state.completed.length > 0 ? <h3>Completed</h3> : ''}
          {this.state.completed ? this.state.completed.map((e,i) => {

            let updateDate = new Date(e.fields['Last Updated']);
            let readableDate = monthNames[updateDate.getMonth()] + ' ' + updateDate.getDay() + ' ' + updateDate.getFullYear();
            return (
              <div className="ListItem card">
                <h3>{e.fields['Project']}</h3>
                <p>Updated {readableDate}</p> <br />
                <Link className="btn" to={'/admin/' + e.id}>Edit</Link>
                <Link className="btn ghostIt" to={'/admin/' + e.id + '/view/'}>Preview</Link>
              </div>
            )
          }) : ''}
        </div>

      </div>

    );
  }
}

export default ListView;
