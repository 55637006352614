import React, { Component } from 'react';
import '../styles/App.css';
import axios from 'axios';
// import bcrypt from 'bcrypt';
import { Base64 } from 'js-base64';

import PortalView from '../Portal/PortalView';

let finalURL;

export default class AdminPanel extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      error: "",
      data: null,
      dataURL: 'https://api.airtable.com/v0/',
      baseId: 'appKladEjQEUksQzf',
      listView: 'view=All',
      currentTable: 'Projects',
      currentRecord: [],
      currentRecordIndex: [],
      currentId: [],
      fallbackRecord: [],
      recordView: false,
      activeModal: false,
      modalType: '',
      dataOffset: '',
      userName: '',
      newRecord: false,
      recordChanges: false,
      listIsVisible: props.recordId == null,
      preview: false,
      isAdmin: false,
    }
  }


  componentWillUpdate = (nextProps, nextState) => {
    if (this.state.loading && !nextState.loading) {
      if (this.props.recordId != null) {
        if (this.props.recordId === 'new') {
          console.log('new-1');
            let today = new Date()
            today = (today.getMonth()+1) + '/' + today.getDate() + '/' + today.getFullYear();
            let emptyRecord = {
              "Location":"",
              "Project":"",
              "Client Information":[],
              "Documents":[],
              "Chapters":[],
              "Images":[],
              "Last Updated":today
            }

            this.setState({
              recordView: true,
              currentRecord: emptyRecord,
              currentId: 'new',
              // noteCharacters: record['Notes'].length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            })

            console.log(emptyRecord);
        } else {
          console.log('yo-1');
          if (nextState.data != null && nextState.data.filter(e => e.id === this.props.recordId)[0]) {
            if (this.props.location.pathname.includes('/view')) {
              this.setState({
                preview: true,
              })
            }

            const record = nextState.data.filter(e => e.id === this.props.recordId)[0].fields;
            if (record) {
              Object.keys(record).forEach(function(key) {
                if (typeof record[key] === 'string' && record[key].indexOf('{') !== -1) {
                  record[key] = JSON.parse('[' + record[key] + ']');
                }
              });
            }




            for (var i in record['Client Information']) {
              if (record['Client Information'][i].password) {
                var decode = Base64.decode(record['Client Information'][i].password);
                record['Client Information'][i].password = decode;
              }
            }

            setTimeout((function() {
              this.setState({
                recordView: true,
                currentRecord: record,
                currentId: nextState.data.filter(e => e.id === this.props.recordId)[0].id,
                // noteCharacters: record['Notes'].length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              })
            }).bind(this), 20);
          } else {
            finalURL = this.state.dataURL + this.state.baseId + '/' + this.state.currentTable + '/' + this.props.recordId;
            return axios
              .get(finalURL)
              .then(response => {
                this.setState({
                  recordView: true,
                  loading: false,
                  error: false,
                  // noteCharacters: response.data.fields['Notes'].length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  currentRecord: response.data.fields,
                });
              })
              .catch(error => {
                this.setState({
                  error: true,
                  message: "404, no record",
                  loading: false,
                });
              });
          }
        }
      } else {
        console.log('yo-3');
        finalURL = this.state.dataURL + this.state.baseId + '/' + this.state.currentTable + '/';
        return axios
          .get(finalURL)
          .then(response => {
            console.log(response);
            this.setState({
              recordView: false,
              loading: false,
              error: false,
              data: response.data.records,
              currentRecord: undefined,
            });
          })
          .catch(error => {
            console.error("error: ", error);
            this.setState({
              error: `${error}`,
              loading: false,
            });
          });
      }
    }
  }


  loadData = () => {
    this.setState({
      loading: true
    });

    //initial load
    setTimeout((function() {
      finalURL = this.state.dataURL + this.state.baseId + '/' + this.state.currentTable + '?' + this.state.listView;

      if (this.state.dataOffset !== '') {
        finalURL = finalURL + '&offset=' + this.state.dataOffset;
      }

      console.log('loadData()');
      return axios
      .get(finalURL)
      .then(response => {
        this.setState({
          data: response.data.records,
          //put it here
          loading: false,
          error: false,
          loadingMore: true,
          dataOffset: response.data.offset,
        });
        setTimeout((function() {
          this.setState({
            loadingMore: false,
          });

          if (this.state.recordView) {
            if (this.state.preview) {
              document.title = "Previewing " + this.state.currentRecord['Project'] + " | CROD Construcciones"
            } else {
              document.title = "Editing " + this.state.currentRecord['Project'] + " | CROD Construcciones"
            }
          } else {
            document.title = "Admin Panel | CROD Construcciones";
          }

        }).bind(this), 100);
      })
      .catch(error => {
        console.error("error: ", error);
        this.setState({
          error: `${error}`,
          loading: false,
        });
      });
    }).bind(this), 10);
  };


  logoutHandler = () => {
    sessionStorage.clear();
    localStorage.clear();
    this.props.history.push('/');
  }

  componentDidMount() {
    if (localStorage.getItem('user')) {
      this.loadData();
    } else {
      this.logoutHandler();
    }
  }


    // Render
    // ----------------------------------------------------
    render() {
      return (
        <div className="AdminPanel">
          {this.viewType}
        </div>
      );
    }

    get viewType() {
      if (this.state.recordView) {
        return (
          <PortalView
            currentRecord={this.state.currentRecord}
            recordView={this.state.recordView}
            closeRecordHandler={this.closeRecordHandler}
            currentId= {this.state.currentId}
            recordChanges= {this.state.recordChanges}
            changeRecordHandler={this.changeRecordHandler}
            updateItem={this.updateItem}
            data={this.state.data}
            saveRecord={this.saveRecord}
            addingDoc={this.addingDoc}
            isAdmin= {this.state.isAdmin}
            logoutHandler={this.logoutHandler}
          />
        );
      } else if (this.state.error) {
        return (
          <div className="error">
            <h1>{this.state.message}</h1>
            <p>Click the x in the top left</p>
          </div>
        );
      } else {
        return (
          <div className="error">
            <h1>Loading</h1>
            <p>If stuck, click the button below</p>

            <div className="btn" onClick={this.logoutHandler}>Back to Login</div>
          </div>
        );
      }
    }
  }
