import React, { Component } from 'react';
import axios from 'axios';

import Featured from './Featured';
import Status from './Status';
import Images from './Images';
import Documents from './Documents';
import CrodDocuments from './CrodDocuments';


import exit from '../assets/exit.png';

import mobilehome from '../assets/mobile-home.png';
import mobilestatus from '../assets/mobile-status.png';
import mobileimages from '../assets/mobile-images.png';
import mobiledocuments from '../assets/mobile-documents.png';

class PortalView extends Component {
  constructor(props) {
    super();
    this.state = {
      activeTab: 'home',
      dataURL: 'https://api.airtable.com/v0/',
      baseId: 'appKladEjQEUksQzf',
    }
  }

  changeTab = e => {
    this.setState({
      activeTab: e,
    })
  }

  componentDidMount() {
    let finalURL = this.state.dataURL + this.state.baseId + '/CROD Documents/';
    return axios
      .get(finalURL)
      .then(response => {
        console.log(response);
        this.setState({
          documents: response.data.records,
        });
      })
      .catch(error => {
        console.error("error: ", error);
        this.setState({
          error: `${error}`,
        });
      });
  }
  // Render
  // ----------------------------------------------------
  render() {
    const { currentRecord, isAdmin } = this.props;
    let wrapClass = 'PortalView ' + this.state.activeTab;
    if (isAdmin) {
      wrapClass += ' isAdmin';
    } else {
      wrapClass += ' isUser';

    }

    const isMobile = window.innerWidth < 480;

    if (isMobile) {
      return (
        <div className={wrapClass}>

          {this.theActiveTab}

          <div className='tabBar'>
            <div className={this.state.activeTab === 'home' ? "mobileTab home isActive" : 'mobileTab home'} onClick={()=>this.changeTab('home')}>
              <img src={mobilehome} alt="home" />
              <p>Home</p>
            </div>
            <div className={this.state.activeTab === 'status' ? "mobileTab status isActive" : 'mobileTab status'} onClick={()=>this.changeTab('status')}>
              <img src={mobilestatus} alt="status" />
              <p>Status</p>
            </div>
            <div className={this.state.activeTab === 'images' ? "mobileTab images isActive" : 'mobileTab images'} onClick={()=>this.changeTab('images')}>
              <img src={mobileimages} alt="images" />
              <p>Images</p>
            </div>
            <div className={this.state.activeTab === 'documents' ? "mobileTab documents isActive" : 'mobileTab documents'} onClick={()=>this.changeTab('documents')}>
              <img src={mobiledocuments} alt="documents" />
              <p>Documents</p>
            </div>
          </div>

        </div>
      );
    } else {
      return (
        <div className={wrapClass}>
          <div className="logout ghostIt" onClick={this.props.logoutHandler}>Logout</div>

          <div className="wrapper IntroArea">
            <h1>{currentRecord['Project']}</h1>
            <div className="clientInfo">
              {currentRecord['Client Information'].map((e,i) => {
                return (
                  <h3>{e.name}</h3>
                )
              })}
            </div>
            <p>{currentRecord['Location']}</p>
          </div>

          <Featured
            currentRecord={currentRecord}
          />


          <div className="well">
            <Status
              currentRecord={currentRecord}
            />
          </div>

          <Images
            currentRecord={currentRecord}
          />

          <Documents
            currentRecord={currentRecord}
          />

          <CrodDocuments
            currentRecord={currentRecord}
            documents={this.state.documents}
          />

        </div>
      );
    }
  }

  get theActiveTab() {
    if (this.state.activeTab === 'home') {
      return (
        <div className="mobileTab home">
          <div className="logout ghostIt" onClick={this.props.logoutHandler}>Logout</div>
          <div className="wrapper well IntroArea">
            <h1>{this.props.currentRecord['Project']}</h1>
            <div className="clientInfo">
              {this.props.currentRecord['Client Information'].map((e,i) => {
                return (
                  <h3>{e.name}</h3>
                )
              })}
            </div>
            <p>{this.props.currentRecord['Location']}</p>
          </div>

          <Featured
            currentRecord={this.props.currentRecord}
          />
        </div>
      );
    } else if (this.state.activeTab === 'status') {
      return (
        <div className="mobileTab status">
            <Status
              currentRecord={this.props.currentRecord}
            />
        </div>
      );
    } else if (this.state.activeTab === 'images') {
      return (
        <div className="mobileTab images">
          <Images
            currentRecord={this.props.currentRecord}
          />
        </div>
      );
    } else if (this.state.activeTab === 'documents') {
      return (
        <div className="mobileTab documents">
          <Documents
            currentRecord={this.props.currentRecord}
          />
          <CrodDocuments
            currentRecord={this.props.currentRecord}
            documents={this.state.documents}
          />
        </div>
      );
    } else {
      return (
        <h1>no active tab</h1>
      )
    }
  }
}

export default PortalView;
