import React, { Component } from 'react';
import Isotope from 'isotope-layout';
import exit from '../assets/exit-black.png';
import download from '../assets/download.png';
import documents from '../assets/document.png';

const isMobile = window.innerWidth < 480;
class Documents extends Component {

  openDocumentos = e => {
    let theId = e + '-documents';
    let theTrigger = e + '-trigger';


    if (document.getElementById(theId).className.includes(' isActive')) {
      document.getElementById(theId).className = 'documentList';
      if (isMobile) {
        document.getElementById(theTrigger).innerHTML = "+";
      } else {
        document.getElementById(theTrigger).innerHTML = "Ver Documentos";
      }
    } else {
      document.getElementById(theId).className += ' isActive';
      if (isMobile) {
        document.getElementById(theTrigger).innerHTML = "-";
      } else {
        document.getElementById(theTrigger).innerHTML = "Cerrar Documentos";
      }
    }

    this.gridLayout()
  }
  // Render
  // ----------------------------------------------------
  render() {
    const { currentRecord } = this.props;
    this.gridLayout()

    if (document.getElementById('img-0')) {
      document.getElementById('img-0').getElementsByTagName('img')[0].onload = function() {
        this.gridLayout()
      }.bind(this);
    }

    return (
      <div className='wrapper well Documents'>
        <h3>Documentos</h3>
        <div className="container">
          {currentRecord['Documents'].map((e,i) => {
            let outerI = i
              let documentLabel = '';
              if (e.documents) {
                if (e.documents.length > 1) {
                  documentLabel = e.documents.length + ' documentos';
                } else {
                  documentLabel = e.documents.length + ' documento';
                }
              }
            return (
              <div className="card image" id={'docGroup-' + i}>
                <div className="cardTop">
                  <img src={documents} alt={e.name + ' icon'} />
                  <h3>{e.category}</h3>
                </div>
                <p className="docLabel">{documentLabel}</p>


                <div className="documentList" id={e.category + '-documents'}>
                  {e.documents ? e.documents.map((e,key) => {
                    return (
                      <div className='document' key={outerI + '-documents-' + key}>
                        <a className="absLink" href={e.url} target="_blank"></a>
                        <p>
                          <span className="title">{e.name}</span>
                          <span className="download"><img src={download} alt="download" /></span>
                        </p>
                      </div>
                    )
                  }) : ''}
                </div>


                {e.documents ? <div className="viewDocumentos" id={e.category + '-trigger'} onClick={()=>this.openDocumentos(e.category)}>{isMobile ? '+' : 'Ver Documentos'}</div> : ''}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  gridLayout() {
    setTimeout(function(){
      var elem = document.querySelector('.Documents .container');
      var iso = new Isotope( elem, {itemSelector: '.image'});
    }, 50);
  }
  gridDestroy() {
    var elem = document.querySelector('.Documents .container');
    var iso = new Isotope( elem, {itemSelector: '.image'});
    iso.destroy()
  }
}

export default Documents;
